import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {uploadVideo} from '../api'
import {toast} from 'react-toastify'
import {
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalHeader, ModalFooter, Spinner
} from 'reactstrap'

class VideoUploader extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    moviePK: PropTypes.number,
  }

  constructor(props){
    super(props)
    this.state = {
      modal: false,
      size: null,
      image: null,
      uploading: false,
      url: '',
      source: 'youtube', // YouTube Archive.org DailyMotion Vimeo
      audio: 'fr', // Muet, Français Español English Italiano
      subtitles: 'none', // No subtitles, Français Español English Italiano
      type: 'trailer', // trailer movie
    }
    this.toggle = this.toggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  async handleSubmit(event){
    event.preventDefault()

    const { url, source, audio, subtitles, type } = this.state
    const movie = this.props.moviePK
    
    const videoData = {
      movie,
      url,
      source,
      audio,
      subtitles,
      type,
    }
    
    this.setState({uploading: true})
    try {
      const result = await uploadVideo(videoData, this.props.token)
      await toast.success(`Vidéo ajoutée`)
      this.setState({uploading: false, modal: false})
    } catch(err) {
      await toast.error(`Une erreur est survenue`)
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal })
  }

  componentDidUpdate(){
    console.log(this.state)
  }

  handleInputChange(event) {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }

  render() {
    return(
      <div>
        <div onClick={this.toggle}>
          {this.props.children}
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Ajouter une video</ModalHeader>
          <ModalBody className="pb-0">
            <Form>
              {this.state.messages}
              <FormGroup style={{maxWidth: '100%', overflow: 'hidden'}}>
                <Label for="url">URL de la vidéo</Label> <br/>
                <Input type='url' name="url" value={this.state.url} onChange={this.handleInputChange}/>
              </FormGroup>
              <FormGroup>
                <Label for="source">Source</Label>
                <Input type="select" name="source" id="source" value={this.state.source} onChange={this.handleInputChange}>
                  <option value="youtube">Youtube</option>
                  <option value="archive">Archive.org</option>
                  <option value="dailymotion">DailyMotion</option>
                  <option value="vimeo">Vimeo</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="audio">Audio</Label>
                <Input type="select" name="audio" id="audio" value={this.state.audio} onChange={this.handleInputChange}>
                  <option value="silent">Muet</option>
                  <option value="fr">Français</option>
                  <option value="es">Espagnol</option>
                  <option value="en">Anglais</option>
                  <option value="it">Italien</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="subtitles">Sous-Titres</Label>
                <Input type="select" name="subtitles" id="subtitles" value={this.state.subtitles} onChange={this.handleInputChange}>
                  <option value="none">Pas de sous-titres</option>
                  <option value="fr">Français</option>
                  <option value="es">Espagnol</option>
                  <option value="en">Anglais</option>
                  <option value="it">Italien</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="type">Type</Label>
                <Input type="select" name="type" id="type" value={this.state.type} onChange={this.handleInputChange}>
                  <option value="trailer">Bande annonce (trailer)</option>
                  <option value="movie">Film (domaine public)</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" onClick={this.handleSubmit} disabled={!this.state.url} className="btn btn-primary btn-lg mb-0"> {this.state.uploading && <Spinner /> || 'Ajouter' }</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}


export default VideoUploader



const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`

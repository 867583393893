import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {uploadImage} from '../api'
import {toast} from 'react-toastify'
import {
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalHeader, ModalFooter, Spinner
} from 'reactstrap'

class ImageUploader extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    translationPK: PropTypes.number, 
  }

  constructor(props){
    super(props)
    this.state = {
      modal: false,
      size: null,
      image: null,
      uploading: false,
    }
    this.toggle = this.toggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
  }

  async handleSubmit(event){
    event.preventDefault()
    const size = this.props.size ? this.props.size : this.state.size
    const imageData = {
      image: this.state.image, 
      movie: this.props.translationPK, 
      size: size
    }
    this.setState({uploading: true})
    try {
      const result = await uploadImage(imageData, this.props.token)
      await toast.success(`Image ajoutée`)
      this.setState({uploading: false, modal: false})
    } catch(err) {
      await toast.error(`Une erreur est survenue`)
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal, image: null })
  }

  componentDidUpdate(){
    console.log(this.state)
  }

  handleInputChange(event) {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }
  
  handleImageChange (event) {
    const file = event.target.files[0]
    const name = event.target.name
  
		this.setState({
      [name]: file,
    })
  }

  render() {
    const imgPreview = this.state.image ? <img style={{maxWidth: '100%'}} src= {URL.createObjectURL(this.state.image)}/> : ''
    return(
      <div>
        <div onClick={this.toggle}>
          {this.props.children}
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Ajouter une image</ModalHeader>
          <ModalBody className="pb-0">
            <Form>
              {this.state.messages}
              <FormGroup style={{maxWidth: '100%', overflow: 'hidden'}}>
                <Label for="image">Image</Label> <br/>
                <input className={this.state.image && "text-success" || ""} type='file' name="image" onChange={this.handleImageChange}/>
              </FormGroup>
              { !this.props.size &&
              <FormGroup>
                <Label for="size">Select</Label>
                <Input type="select" name="size" id="size" value={this.state.size} onChange={this.handleInputChange}>
                  <option value="poster">Image de couverture (affiche)</option>
                  <option value="cover">Fond d'écran</option>
                </Input>
              </FormGroup>
              }
              <div className="mb-3 text-center">
                {imgPreview}
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" onClick={this.handleSubmit} disabled={!this.state.image} className="btn btn-primary btn-lg mb-0"> {this.state.uploading && <Spinner /> || 'Envoyer' }</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}


export default ImageUploader



const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`

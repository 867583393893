import React from 'react'
import PropTypes from 'prop-types'
import { Form, Table, FormGroup, CustomInput, Label} from 'reactstrap'
import { addWatchOn, deleteWatchOn } from '../../api'

class FormWatchOn extends React.Component {

    static propTypes = {
        token: PropTypes.string, 
        translationPk: PropTypes.string,
        watchOn: PropTypes.array, // [{movie_translation, streaming_service, price}]
    }

    state = {
        disneyplus: false,
        disneyplusid: null,
        netflix: false,
        netflixid: null,
        amazonprimevideo: false,
        amazonprimevideoid: null,
    }

    componentDidMount(){
        const {watchOn} = this.props
        if (watchOn && watchOn.length) {
            watchOn.forEach(i => {
                if(i.streaming_service === 1) this.setState({disneyplus: true, disneyplusid: i.id})
                if(i.streaming_service === 7) this.setState({netflix: true, netflixid: i.id})
                if(i.streaming_service === 8) this.setState({amazonprimevideo: true, amazonprimevideoid: i.id})
            });
        }
    }

    handleInputChange = (event) => {
        const {token, translationPk} = this.props
        const name = event.target.name;
        const checked = event.target.checked;

        let streamingServiceId

        if (name === 'disneyplus')              { streamingServiceId = 1 }
        else if (name === 'netflix')            { streamingServiceId = 7 }
        else if (name === 'amazonprimevideo')   { streamingServiceId = 8 }

        if (checked){
            addWatchOn({movie_translation: translationPk, streaming_service: streamingServiceId, price: null}, token)
        } else {
            let watchOnId 
            if (streamingServiceId === 1)       { watchOnId = this.state.disneyplusid }
            else if (streamingServiceId === 7)  { watchOnId = this.state.netflixid }
            else if (streamingServiceId === 8)  { watchOnId = this.state.amazonprimevideoid }
            deleteWatchOn(watchOnId, token)
        }
        
    
        this.setState({
          [name]: checked
        });
    }
    render() {
        return (
            <Form onSubmit={() => {}}>
                <h2 className="helsinki mt-5 mb-4">Comment regarder</h2>
                <FormGroup>
                    <div>
                    <CustomInput type="checkbox" name="disneyplus" id="disneyplus" label="Disney Plus" checked={this.state.disneyplus} onChange={this.handleInputChange}/>
                    <CustomInput type="checkbox" name="netflix" id="netflix" label="Netflix" checked={this.state.netflix} onChange={this.handleInputChange}/>
                    <CustomInput type="checkbox" name="amazonprimevideo" id="amazonprimevideo" label="Amazon Prime Video" checked={this.state.amazonprimevideo} onChange={this.handleInputChange}/>
                    </div>
                </FormGroup>
            </Form>
        )
    }
}

export default FormWatchOn
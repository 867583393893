import React from "react"
import { Router } from "@reach/router"
import {navigate} from 'gatsby'
import {connect} from 'react-redux'
import Layout from "../components/Layout"
import MovieEditor from "../components/MovieEditor"

const App = ({user, token}) => {

  if (typeof window === "undefined") return '';

  if (!token) {
    navigate(`/login`)
  }
  return (
  <Layout>
    <Router>
      <MovieEditor path="/edit/:slug" user={user} token={token}/>
      <MovieEditor path="/:lang/edit/:slug" user={user} token={token}/>
    </Router>
  </Layout>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
})

export default connect(mapStateToProps)(App)
import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Col, Container, Form, FormGroup, FormText, Label, Spinner, Input, Row, Table, UncontrolledAlert } from 'reactstrap'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText } from 'reactstrap'

import {GENRES_FR, COUNTRIES_FR, LANGUAGES_FR} from '../const'
import {editMovie, editMovieTranslation, getMovieBySlug, deleteVideo, deleteImage} from '../api'
import {css} from '@emotion/react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import ImageUploader from './ImageUploader'
import VideoUploader from './VideoUploader'
import FormWatchOn from './MovieEditor/FormWatchOn'


const genres = []
const countries = []
const languages = []
for (let key in COUNTRIES_FR) {
  countries.push({ value: key, label: COUNTRIES_FR[key] })
}
for (let key in LANGUAGES_FR) {
  languages.push({ value: key, label: LANGUAGES_FR[key].replace(LANGUAGES_FR[key][0], LANGUAGES_FR[key][0].toUpperCase()) })
}
for(let key in GENRES_FR) {
  genres.push({ value: key, label: GENRES_FR[key] })
}


class MovieEditor extends React.Component{
  static propTypes = {
    token: PropTypes.string, 
    slug: PropTypes.string,
    error: PropTypes.array,
  }

  state = {
    loading: false,
    activeTab: '1',
    movie: {
      id: null,
      original_title: null,
      original_language: null,
      budget: null,
      box_office: null,
      runtime: null,
      genres: null,
      release: null,
      genre: null,
    },
    translation: {
      id: null,
      title: null,
      language: null,
      synopsis: null,
      images: null,
      created: null,
    },
  }

  async componentDidMount(){
    const result = await getMovieBySlug(this.props.slug, this.props.token)
    this.setState({
      movie: {
        id: result.movie.id,
        original_title: result.movie.original_title,
        original_language: result.movie.original_language,
        original_country: result.movie.original_country,
        budget: result.movie.budget,
        box_office: result.movie.box_office,
        runtime: result.movie.runtime,
        genres: result.movie.genres,
        release: result.movie.release,
        videos: result.movie.videos,
        genre: result.movie.genre,
      },
      translation: {
        id: result.id,
        title: result.title,
        language: result.language,
        synopsis: result.synopsis,
        images: result.images,
        created: result.created,
        watchOn: result.watch_on,
      },
    })
  }

  handleSubmitGeneral = async (event) => {
    event.preventDefault()
    const data = {title: this.state.translation.title, synopsis: this.state.translation.synopsis}
    try {
      const result = await editMovieTranslation(data, this.props.slug, this.props.token)
      toast.success(`Film mis à jour !`, {position:"top-right"})
      if (this.props.slug !== result.slug) 
        await navigate(`/edit/${result.slug}`)
    } catch (err) {
      const messages = JSON.parse(err.message)
      console.log(messages)
      for (let key in messages) {
        toast.error(`${key}: ${messages[key]}` || 'Something went wrong', {position:"top-right"})
      }
    }
  }

  handleSubmitDetails = async (event) => {
    event.preventDefault()
    const data = {...this.state.movie}
    delete data.videos
    delete data.genre
    try {
      await editMovie(data, this.props.token)
      await toast.success(`Détails mis à jour !`, {position:"top-right"})
    } catch (err) {
      const messages = JSON.parse(err.message)
      console.log(messages)
      for (let key in messages) {
        toast.error(`${key}: ${messages[key]}` || 'Something went wrong', {position:"top-right"})
      }
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({[name]: value})
  }

  handleGeneralInputChange = (event) => {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
      translation:{
        ...this.state.translation,
        [name]: value
      }
    })
  }

  handleDetailsInputChange = (event) => {
		const target = event.target;
		const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
      movie:{
        ...this.state.movie,
        [name]: value
      }
    })
  }

  // Genres shape: [{value: 1, label: Action}, ...]
  // Change original_language, original_country, genres.
  handleGenreChange             = (newValue, actionMeta) => this.setState({ movie: {...this.state.movie, genres: newValue.map(i => i.value)} })
  handleGenreV2Change           = (newValue, actionMeta) => this.setState({ movie: {...this.state.movie, genre: newValue.map(i => i.value)} })
  handleOriginalLanguageChange  = (newValue, actionMeta) => this.setState({ movie: {...this.state.movie, original_language: newValue.map(i => i.value)} })
  handleOriginalCountryChange   = (newValue, actionMeta) => this.setState({ movie: {...this.state.movie, original_country: newValue.map(i => i.value)} })

  render(){
    if (!this.state.movie.id) return <div style={{minHeight:'60vh'}} className="d-flex"><Spinner className="m-auto" color="primary" /></div>

    return (
      <Container style={{minHeight:'60vh'}}>
        <Row>
          <Col sm="3">
            <Nav pills vertical className="mt-5 mb-4">
              <NavItem>
                <NavLink className={this.state.activeTab === '1' && 'active' || ''} onClick={() => { this.toggle('1'); }}> {/* className={classnames({ active: this.state.activeTab === '1' })} */}
                  Général
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.activeTab === '2' && 'active' || ''} onClick={() => { this.toggle('2'); }}> {/* className={classnames({ active: this.state.activeTab === '1' })} */}
                  Détails
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.activeTab === '3' && 'active' || ''} onClick={() => { this.toggle('3'); }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.activeTab === '4' && 'active' || ''} onClick={() => { this.toggle('4'); }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Vidéos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-secondary font-italic font-weight-lighter" onClick={() => {  }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Prix remportés (à venir)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-secondary font-italic font-weight-lighter" onClick={() => {  }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Personnes (à venir)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-secondary font-italic font-weight-lighter" onClick={() => {  }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Historique (à venir)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-secondary font-italic font-weight-lighter" onClick={() => {  }}>  {/* className={classnames({ active: this.state.activeTab === '2' })} */}
                  Statistiques (à venir)
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="9">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <FormGeneral 
                  translation={this.state.translation} 
                  handleInputChange={this.handleGeneralInputChange} 
                  handleSubmit={this.handleSubmitGeneral}
                />
              </TabPane>
              <TabPane tabId="2">
                <FormDetails 
                  movie={this.state.movie} 
                  handleInputChange={this.handleDetailsInputChange} 
                  handleSubmit={this.handleSubmitDetails}
                  handleGenreChange={this.handleGenreChange}
                  handleGenreV2Change={this.handleGenreV2Change}
                  handleOriginalLanguageChange={this.handleOriginalLanguageChange} 
                  handleOriginalCountryChange={this.handleOriginalCountryChange} 
                />
              </TabPane>
              <TabPane tabId="3">
                <FormImages images={this.state.translation.images} token={this.props.token} translationPK={this.state.translation.id}/>
              </TabPane>
              <TabPane tabId="4">
                <FormVideos videos={this.state.movie.videos} token={this.props.token} moviePK={this.state.movie.id}/>
              </TabPane>
              <TabPane tabId="4">
                <FormWatchOn watchOn={this.state.translation.watchOn} token={this.props.token} translationPk={this.state.translation.id}/>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    )
  }
}

const FormGeneral = ({translation, handleInputChange, handleSubmit}) => {
  
  return (
    <Col sm="9">
      <Form onSubmit={handleSubmit}>
        <h2 className="helsinki mt-5 mb-4">Informations générales </h2> 

        <FormGroup>
          <Label for="title">Titre <span className="text-secondary font-weight-lighter">(en Français)</span></Label>
          <Input type="text" name="title" id="title" placeholder="" value={translation.title} onChange={handleInputChange}/>
        </FormGroup>

        <FormGroup>
          <Label for="exampleText">Synopsis</Label>
          <Input type="textarea" rows="12" name="synopsis" id="synopsis" value={translation.synopsis} onChange={handleInputChange}/>
        </FormGroup>

        <button type="submit" className="btn btn-link btn-sm float-right pr-0">Mettre à jour</button>
      </Form>
    </Col>
  )
}
const FormDetails = ({movie, handleInputChange, handleGenreChange, handleGenreV2Change, handleSubmit, handleOriginalLanguageChange, handleOriginalCountryChange}) => {

  const initialGenres = movie.genres.map(i => genres.filter(j => i === j.value)).flat()
  const originalLanguages = movie.original_language.map(i => languages.filter(j => i === j.value)).flat()
  const originalCountries = movie.original_country.map(i => countries.filter(j => i === j.value)).flat()

  const initialGenre = movie.genre.map(i => genres.filter(j => parseInt(i.genre) === parseInt(j.value))).flat()

  return (
    <Col sm="9">
      <Form onSubmit={handleSubmit}>
        <h2 className="helsinki mt-5 mb-4">détails du film</h2>
        <FormGroup>
          <Label for="original_title">Titre original <span className="text-secondary font-weight-lighter">(dans la langue d'origine)</span></Label>
          <Input type="text" name="original_title" id="original_title" placeholder="Titre original du film" value={movie.original_title} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label>Pays d'origine</Label>
          <Select
            defaultValue={originalCountries}
            closeMenuOnSelect={true}
            onChange={handleOriginalCountryChange}
            isMulti
            placeholder=""
            options={countries}
          />
        </FormGroup>
        <FormGroup>
          <Label>Langue<span className="text-secondary font-weight-lighter">(s)</span> d'origine</Label>
          <Select
            defaultValue={originalLanguages}
            closeMenuOnSelect={true}
            onChange={handleOriginalLanguageChange}
            isMulti
            placeholder=""
            options={languages}
          />
        </FormGroup>
        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="release">Date de sortie</Label>
              <Input type="date" name="release" id="release" placeholder="" value={movie.release} onChange={handleInputChange}/>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="runtime">Durée<span className="text-secondary font-weight-lighter"> (en minutes)</span></Label>
              <Input type="number" name="runtime" id="runtime" placeholder="" value={movie.runtime} onChange={handleInputChange}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            <FormGroup>
              <Label for="budget">Budget<span className="text-secondary font-weight-lighter"> ($)</span></Label>
              <Input type="number" name="budget" id="budget" placeholder="" value={movie.budget} onChange={handleInputChange}/>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="box_office">Box office<span className="text-secondary font-weight-lighter"> ($)</span></Label>
              <Input type="number" name="box_office" id="box_office" placeholder="" value={movie.box_office} onChange={handleInputChange}/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>Genres (v1)</Label>
          <Select
            defaultValue={initialGenres}
            closeMenuOnSelect={false}
            onChange={handleGenreChange}
            isMulti
            placeholder=""
            options={genres}
          />
        </FormGroup>
        <FormGroup>
          <Label>Genres (v2)</Label>
          <Select
            defaultValue={initialGenre}
            closeMenuOnSelect={false}
            onChange={handleGenreV2Change}
            isMulti
            placeholder=""
            options={genres}
          />
        </FormGroup>
        {/* <FormGroup>
          <Label>Langue<span className="text-secondary font-weight-lighter">(s)</span> parlée<span className="text-secondary font-weight-lighter">(s)</span></Label>
          <Select
            closeMenuOnSelect={false}
            components={makeAnimated()}
            onChange={handleGenreChange}
            isMulti
            placeholder=""
            options={languages}
          />
        </FormGroup> */}
        {/* <FormGroup>
          <Label>Durée<span className="text-secondary font-weight-lighter">(s)</span></Label>
          <Select
            closeMenuOnSelect={false}
            components={makeAnimated()}
            onChange={handleGenreChange}
            isMulti
            placeholder=""
            options={genres}
          />
        </FormGroup> */}
        {/* <FormGroup>
          <Label>Tags</Label>
          <Select
            closeMenuOnSelect={false}
            components={makeAnimated()}
            onChange={handleGenreChange}
            isMulti
            placeholder=""
            options={genres}
          />
        </FormGroup> */}

        <button type="submit" className="btn btn-link btn-sm float-right pr-0">Mettre à jour</button>
      </Form>
    </Col>
  )
}
const FormImages = ({images, token, translationPK}) => {
  const allCovers = []
  const allPosters = []
  images.forEach(img => {
    if (img.size === "poster") {
      allPosters.push(<img src={img.image.replace('s3.amazonaws.com/', '')} key={img.image} onClick={() => deleteImage(img.id, token)} className="mr-3 mb-3 rounded" width="136" height="180" />)
    } else if (img.size === "cover") {
      allCovers.push(<img src={img.image.replace('s3.amazonaws.com/', '')} key={img.image} onClick={() => deleteImage(img.id, token)} className="mr-3 mb-3" width="168" height="95"/>)
    }
  })
  return (
    <Form onSubmit={() => {}}>
      <h2 className="helsinki mt-5 mb-4">Modifier images</h2>
      {/* <ImageUploader token={token} translationPK={translationPK}>
        <span className="btn btn-outline-primary mb-3" >Ajouter une image</span>
      </ImageUploader> */}
      <h4>Images de couverture</h4>
      <div className="d-flex flex-wrap">
      <ImageUploader token={token} translationPK={translationPK} size="poster">
        <div className="rounded border d-flex justify-content-center align-items-center btn mr-3 mb-3" style={{height:180, width: 138}}>➕</div>
      </ImageUploader>
        {allPosters}
      </div>
      <h4>Fonds d'écran</h4>
      <div className="d-flex flex-wrap">
        <ImageUploader token={token} translationPK={translationPK} size="cover">
          <div className="rounded border d-flex justify-content-center align-items-center btn mr-3 mb-3" style={{height:95, width: 168}}>➕</div>
        </ImageUploader>
        {allCovers}
      </div>

      {/* <button type="submit" className="btn btn-link btn-sm float-right pr-0 disabled">Mettre à jour</button> */}
    </Form>
  )
}

const FormVideos = ({videos, token, moviePK}) => {
  const allTrailers = []
  const allMovies = []
  videos.forEach(video => {
    if (video.type === "movie") {
      allMovies.push(
        <tr key={video.id}>
          <td>{video.source}</td>
          <td>{video.audio}</td>
          <td>{video.subtitles}</td>
          <td><a href={video.url} target="_blank" rel="nofollow noreferrer noopener">lien</a></td>
          <td><button className="btn btn-link text-danger m-0 p-0" onClick={() => deleteVideo(video.id, token)} >Supprimer</button></td>
        </tr>
      )
    } else if (video.type === "trailer") {
      allTrailers.push(
        <tr key={video.id}>
          <td>{video.source}</td>
          <td>{video.audio}</td>
          <td>{video.subtitles}</td>
          <td><a href={video.url} target="_blank" rel="nofollow noreferrer noopener">lien</a></td>
          <td><button className="btn btn-link text-danger m-0 p-0" onClick={() => deleteVideo(video.id, token)}>Supprimer</button></td>
        </tr>
      )
    }
  })
  return (
    <Form onSubmit={() => {}}>
      <h2 className="helsinki mt-5 mb-4">Modifier vidéos</h2>
      <VideoUploader token={token} moviePK={moviePK} >
        <div className="btn btn-outline-secondary mb-3" >Ajouter une vidéo </div>
      </VideoUploader>
      <h4>Trailers</h4>
      <Table  size="sm">
        <thead>
          <tr>
            <th>Source</th>
            <th>Audio</th>
            <th>Sous-titres</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allTrailers}
        </tbody>
      </Table>
      
      <h4>Films</h4>
      <span className="text-secondary font-italic font-weight-lighter" >Pour les films libres de droits</span>
      <Table  size="sm">
        <thead>
          <tr>
            <th>Source</th>
            <th>Audio</th>
            <th>Sous-titres</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allMovies}
        </tbody>
      </Table>

      {/* <button type="submit" className="btn btn-link btn-sm float-right pr-0 disabled">Mettre à jour</button> */}
    </Form>
  )
}

export default MovieEditor

/*

{
  "id": 11,
  "movie": {
      "id": 10,
      "original_title": "Morgane et ses nymphes",
      "original_language": "fr",
      "budget": null,
      "box_office": null,
      "runtime": null,
      "genres2": [],
      "genres": [
          10,
          13,
          17
      ],
      "release": "1971-02-17"
  },
  "title": "Morgane et ses nymphes",
  "language": "fr",
  "synopsis": "Film fantastico-érotique français réalisé par Bruno Gantillon, sorti en 1971.\r\nLa distrubution de ce films est quasi-exclusivement féminine.",
  "images": [
      {
          "movie": 11,
          "image": "http://localhost:8000/media/poster_morganEtSesNymphes_hXDgnbW.jpg",
          "size": "poster"
      },
      {
          "movie": 11,
          "image": "http://localhost:8000/media/next_morganEtSesNymphes.jpg",
          "size": "cover"
      }
  ],
  "created": "2019-03-07"
}

*/